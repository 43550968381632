import React from "react";
import {CardAboutBlock} from "../elements/CardAboutBlock";
import {aboutBlockCards} from "../../data/content";

export const About = (props) => {

    const aboutCardsRender = aboutBlockCards.map(card => (
        <CardAboutBlock key={card.cardId + card.title}
                        imagePath={card.imagePath}
                        title={card.title}
                        description={card.description}
                        cardId={card.cardId}
                        deviceType={props.deviceType}
        />
    ))

    return (
        <section className='about-block app-content-container'>
            <div className="about-block-header">
                <h2 className='text blue-32-24-20'>О компании</h2>
                <div className="description">
                    <article className='description-title'>Мы реализуем проекты в области развития корпоративных
                        ИТ-систем, цифровизации государственных и
                        коммерческих предприятий.
                    </article>
                    <p className='description-subtitle'>Наша компания (ООО "НТ Разработки", ИНН 7702848468, ОГРН 5147746322830)
                        предлагает только оптимальные по функциональности, срокам реализации и бюджету решения,
                        улучшающие производительность и окупаемость вашего бизнеса в современной конкурентной среде.</p>
                </div>
            </div>

            <div className='about-block-cards '>
                {aboutCardsRender}
            </div>
        </section>
    )
}
